import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const CDSTechStack = () => (
  <Layout>
    <SEO
      title="The Tech Stack For My Site and Why"
      description="When I decided to re-build my website, I wanted to make use of the things I had learned and keep it simple to avoid any problems later on"
    />
    <h1>The Tech Stack For My Site and Why</h1>
    <p>When I decided to rebuild my website, I wanted to make use of the things I had learned and keep it simple to avoid any problems later on. The site needed to be a simple blog where I can publish my writing and any applications I build. I wanted it to be fast i.e load quickly.  It didn’t need any user login or anything that required a backend so I decided to go server less. The other requirement was that it was cheap because it was not intended to generate any money, just a place for me to publish my work.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/JifPb3B.jpg"
        alt="My Blog Website Tech Stack"
        description="a server indicating the campbell data science web stack store"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@tvick"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Taylor Vick
      </a>
      {" "} on Unsplash
    </p>

    <p>I’ll take you through some of the major choices I made along with things I’ve learned along the way.</p>

    <h2>Homepage</h2>
    <p>If you go to my homepage, you’ll see that it is very basic. It doesn't have any images except for the few icons and there is no pagination, just a list of articles. This might seem a bit strange because the homepage is supposed to be amazing to welcome people in and get them excited to be here, isn’t it?</p>
    <p>At first, I thought so too and I had plans to go back and make it special. I didn’t do it initially because it is one of those hurdles that’s easy to get stuck on and that’s where procrastination sets in. A simple homepage let me get on with things quickly and move onto the next task.</p>
    <p>So what did I learn? Most people don’t even see the homepage. I have Analytics & search console installed and they tell me that my homepage is one of the least visited pages on the site. I’ll get more into why this is in the SEO section.</p>
    <p>The homepage is a simple directory. I have a list of articles and pages that each have a title and short description that reflects the content. There is a short paragraph at the top of the page that explains the website so you know you’re in the right place.</p>
    <p>The two glaring things that stand out from most websites are that there are no preview images and not pagination or filters. Having no preview images makes the homepage text dominated, this isn’t as bad as it sounds because I’ve made the effort to  break things up a bit by having well-spaced/sized copy.</p>
    <p>Having no pagination is fine with me, I don’t think I will be changing that any time soon unless I see good reason too. Most modern websites have done away with pagination and have instead gone with infinite scroll. All of the links to my content simply load all at once. I’m sure you can see why I decided not to include image previews now but this could be solved by lazy loading - i.e. the content loads as the user scrolls down the page.</p>
    <p>A filter is something I want to implement as the amount of content increases. Right now, if you want to find an article from the homepage, you have to scroll until you find what you are looking for. A much better option would be to have a filter that narrows down the content based on what the user types into a search input. I like the idea of having a smart search that doesn’t have to rely on exact match keywords and can return results based on understanding.</p>

    <h2>Don’t need a CMS, Use github</h2>
    <p>I mentioned how the homepage is a list of previews with links to articles. As you might be able to tell, I update the site quite regularly. So, how do I publish new content? In my mind, there are three ways to update a site like mine with content; 1 - create the new pages and re-build the site then publish the whole thing. 2 - Have the site read from a database and automatically update when new information is provided. 3 - Use a CMS (content management system).</p>
    <p>I manage this site myself and in an effort to keep things simple, I decided to go with option 1 which is to rebuild and publish the site each time. I use Github for version control and the site is built using React and Gatsby. I type the content out in notes then edit it in word to correct any mistakes. Then I format it in HTML adding any pictures and links. To publish a Gatsby project, it needs to be built first. Once I've built it, I push the project to Github, then I push the build directory to a different repository.</p>
    <p>My hosting is set up to pull information from github when a change occurs. It means I can do all the publishing through the command line and don’t have to login and do anything on my hosting account.</p>
    <p>I had to copy and replace the whole build file before I found a host that could link to Github. That was a pain. The good thing about using Github as version control is that only the necessary files get pushed, not the whole project.</p>

    <h2>Gatsby for speed and SEO</h2>
    <p>I decided to use the Gatsbyjs framework for a few reasons. The first being that it uses server side rendering. Second for its templating and structuring capabilities then for its speed.</p>
    <p>Server-side rendering is necessary for modern SEO. When the google bot crawls a website, it reads through and makes sense of HTML. React is a Javascript framework that is client side-rendered. Using Gatsby on top allows server side rendering as I’ve mentioned. Google claims that it can read Javascript and I don’t doubt it can. But, in my experience, it doesn’t prioritise small sites or pages.</p>
    <p>If you are curious about if a page is server-side or client-side rendered, right click the page and click inspect. If you see HTML it’s server side, if not, it’s probably client side.</p>
    <p>SEO is a big part of the marketing strategy of most businesses. Especially if you don’t already have a huge presence on one of the social medias. This way, if someone searches for a term that you have a product or service for and you are a leader in that solution, you should be coming up high. It’s one way brands stay relevant.</p>
    <p>Gatsby and its SEO capabilities allow the content that I write to get ranked in the first place. With some effort, I can get ranked on the first page or even the number one spot.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default CDSTechStack
